<template>
  <div class="data-field">
    <span class="label">{{ label }}</span>
    <span class="value">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.data-field {
  display: flex;
  flex-direction: column;
  padding-top: .5rem;
  border-bottom: 2px solid $cortex;
  @include text-style('interface', 'small', 'medium');

  .label {
    white-space: nowrap;
    margin-right: 1rem;
    color: $hillcock;
    flex: 1;
  }
  .value {
    padding-top: .25rem;
    color: $black;
    flex: 3;
  }

  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
    padding-bottom: .5rem;

    .value {
      padding-left: 1.5rem;
    }
  }
}
</style>
