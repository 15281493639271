<template>
  <div class="notification-tray">
    <BfAlert
      v-for="notification in notifications"
      :key="notification.id"
      :type="notification.type || info"
      :icon="notificationIcon(notification)"
      :closeable="notification.closeable || false"
      @dismiss="handleDismissNotification(notification.id)"
    >
      {{ notification.message }}
    </BfAlert>
  </div>
</template>

<script>
// TODO: Remove this component when we migrate to an alert bar.
import BfAlert from '@/components/BfAlert/BfAlert'

export default {
  components: {
    BfAlert
  },
  props: {
    notifications: {
      type: Array,
      required: true
    }
  },
  methods: {
    isWarningOrError(notificationType) {
      return notificationType === 'warning' || notificationType === 'error'
    },

    notificationIcon(notification) {
      if (notification.icon) {
        return notification.icon
      }
      if (this.isWarningOrError(notification.type)) {
        return 'warning'
      }
      return 'checkmark'
    },

    handleDismissNotification(notificationId) {
      this.$emit('dismiss-notification', notificationId)
    }
  }
}
</script>

<style lang="scss">
.notification-tray .bf-alert:last-child {
  margin-bottom: 1rem;
}
</style>
