<template>
  <form
    class="data-form"
    :class="{ editing }"
    aria-label="Participant Information"
  >
    <DataFormSection
      v-for="(section,index) in sections"
      :key="index"
      class="data-form__section"
      :class="{ fullWidth }"
      :section="section"
      :editing="editing"
      :value="value"
      @input="$emit('input', $event)"
    />
  </form>
</template>

<script>
import DataFormSection from './DataFormSection'

export default {
  components: {
    DataFormSection
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    sections: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  .data-form__section {
    display: grid;
    grid-column-gap: 2rem;
    grid-template: repeat(var(--section-rows), auto) / 1fr 1fr;
    grid-auto-flow: var(--grid-flow);

    &.fullWidth {
      display: block;

      .form-field {
        margin-bottom: 1.5rem;
      }
    }

    h3 {
      grid-column: 1 / span 2;
      margin: 0;
      margin-bottom: 1.5rem;
      color: $black;
      @include text-style('title', 'medium', 'medium');
    }

    /* Add fallback for smaller screen sizes */
    @media screen and (max-width: $breakpoint-mobile) {
      grid-template: auto / 100%;

      h3 {
        grid-column: 1;
      }
    }
  }
</style>
