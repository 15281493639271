<template>
  <div
    :style="cssVars"
    :class="{ 'editing': editing }"
  >
    <h3 v-if="section.title">
      {{ section.title }}
    </h3>
    <div
      v-for="field in sectionFields"
      :key="field.id"
      class="data-form__field"
      :class="`field--${field.id} editable--${field.editable}`"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :rules="field.validationRules"
        :name="`${field.id}Validation`"
        mode="aggressive"
      >
        <BfDatePicker
          v-if="field.type === 'date'"
          v-model="value[field.id]"
          class="data-form__input"
          :name="field.id"
          :type="field.type"
          :disabled="!editing || false === field.editable"
          :error="errors[0]"
          :label="field.label"
          :subtext="field.subtext"
          :disable-before="field['disable-before']"
          :disable-after="field['disable-after']"
          :locked="field.isLocked"
          @input="value => updateModel(field.id, value)"
        />
        <BfSelect
          v-else-if="field.type === 'select' && ((!field.isLocked && field.isConfirmation) || !field.isConfirmation)"
          ref="selectFields"
          v-model="value[field.id]"
          class="data-form__select"
          :name="field.id"
          :disabled="!editing || false === field.editable"
          :error="errors[0]"
          :locked="field.isLocked"
          :label="field.label"
          :subtext="field.subtext"
          :popper-class="field.popperClass"
          @input="value => updateModel(field.id, value)"
        >
          <el-option
            v-for="{ key, label, value: optionValue } in field.possibleValues"
            :key="key"
            :label="label"
            :value="optionValue"
          />
        </BfSelect>
        <SearchableMultiSelect
          v-else-if="field.type === 'multiselect'"
          v-model="value[field.id]"
          class="data-form__multiselect"
          :class="{ 'locked': field.locked }"
          :options="field.possibleValues"
          :read-only="!editing || !field.editable"
          :name="field.id"
          :disabled="!editing || false === field.editable"
          :error="errors[0] || field.errors"
          :label="field.label"
          :instructions="field.instructions"
          :subtext="field.subtext"
          :optional="field.optional"
          :popper-class="field.popperClass"
          @input="value => updateModel(field.id, value)"
        />
        <BfInput
          v-else
          v-model="value[field.id]"
          class="data-form__input"
          :name="field.id"
          :type="field.type"
          :disabled="!editing || false === field.editable"
          :error="errors[0]"
          :label="field.label"
          :subtext="field.subtext"
          @input="value => updateModel(field.id, value)"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import BfInput from '@/components/BfInput/BfInput'
import BfSelect from '@/components/BfSelect/BfSelect'
import SearchableMultiSelect from '@/components/SearchableMultiSelect/SearchableMultiSelect'
import { propOr } from 'ramda'

export default {
  components: {
    BfDatePicker,
    BfInput,
    BfSelect,
    SearchableMultiSelect
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    section: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    cssVars() {
      return {
        '--section-rows': this.section.rows || 1,
        '--grid-flow': this.section.gridFlow || 'row'
      }
    },
    sectionFields() {
      return propOr([], 'fields', this.section)
    }
  },
  watch: {
    editing(isEditing) {
      // Element UI is fantastically stupid in how it handles the height of select elements.
      // When the element is first loaded on the page, Element UI will take its height and store it in a property,
      // which is then assigns directly to a `style` attribute whenever the element is updated. This prevents us
      // from using CSS to increase the height when making the DataForm editable.
      // To get around this, we're overriding that property directly when the form becomes editable.
      const selectElements = this.$refs.selectFields.map(field => {
        return field.$refs['el-select']
      })
      this.$nextTick(() => {
        if (isEditing) {
          selectElements.filter(element => !element._props.disabled).forEach(element => {
            element.initialInputHeight = 40
          })
        } else {
          selectElements.forEach(element => {
            element.initialInputHeight = 24
          })
        }
      })
    }
  },
  methods: {
    updateModel(id, value) {
      const payload = {
        ...this.value,
        [id]: value
      }
      this.$emit('input', payload)
    }
  }
}
</script>

<style lang="scss">
.data-form__field {
  position: relative;
  margin-bottom: 2rem;
  padding: 0;
  color: $black;

  label.input-label {
    margin-bottom: .5rem;
    white-space: nowrap;
    @include text-style('interface', 'medium', 'medium');
  }

  .field-wrap .el-input .el-input__inner {
    min-height: 2.5rem;
    padding: 0 1rem;
    color: $black;
    @include text-style('interface', 'small', 'regular');
    transition: none;

    &[disabled] {
      border-bottom: 2px solid $cortex;
    }
  }

  // When the field is read-only, hide the caret for select fields
  .bf-select[disabled] .el-input__suffix {
    display: none;
  }

  // todo: test
  .searchable-multi-select .has-error {
    .el-input__suffix {
      display: none;
    }

    .icon-wrapper {
      height: 2.5rem;
    }
  }

  // add elipsis for text overflow in read-only mode.
  .data-form__input[disabled],
  .data-form__select[disabled] {
    &.bf-input[disabled],
    &.bf-date-picker[disabled],
    &.bf-select[disabled] {
      .el-input__inner {
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
