<template>
  <div :class="`content-card ${size}`">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
      validator: val => ['wide', 'narrow', ''].includes(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-card {
    background: $white-matter;
    border: 1px solid $axon;
    border-radius: 2px;
    padding: 1rem;
    margin: 0 0 1rem;

    &.wide {
      margin-left: -1rem;
      margin-right: -1rem;
      border-left: 0;
      border-right: 0;
      padding: 2rem;
    }

    &.narrow {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
</style>
