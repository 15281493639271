<template>
  <div class="user-settings">
    <PageHeader :title="pageTitle">
      <template
        v-if="!this.$route.params.userId"
        v-slot:actions
      >
        <el-button
          class="user-settings__edit-profile"
          type="primary"
          :disabled="editing && !isUserValid"
          @click="toggleEditMode"
        >
          <span v-if="!editing">Edit Profile</span>
          <span v-else>Save Profile</span>
        </el-button>
      </template>
    </PageHeader>
    <ContentCard>
      <NotificationTray :notifications="notifications" />
      <ValidationObserver ref="userValidator">
        <DataForm
          :sections="sections"
          :value="user"
          :editing="editing"
        />
      </ValidationObserver>
      <template v-if="isCurrentUser">
        <h3>Change Password</h3>
        <div
          v-if="!changePasswordFormVisible"
          class="change-password-grid"
        >
          <DataField
            label="Current Password"
            class="change-password-field"
          >
            **********
          </DataField>
        </div>
        <ValidationObserver
          v-else
          v-slot="{ invalid }"
          class="change-password-grid"
          slim
        >
          <form @submit.prevent="handleChangePasswordSubmit">
            <ValidationProvider
              v-slot="{ errors }"
              name="oldPassword"
              rules="required"
              mode="aggressive"
              class="change-password-field"
            >
              <BfInput
                v-model="oldPassword"
                label="Old Password"
                name="oldPassword"
                type="password"
                autofocus
                :error="errors[0]"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="newPassword"
              rules="required|password"
              mode="aggressive"
              class="change-password-field"
            >
              <BfPassword
                v-model="newPassword"
                label="New Password*"
                name="newPassword"
                autofocus
                :error="errors[0]"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|sameAsPassword:@newPassword"
              class="change-password-field"
              mode="aggressive"
            >
              <BfInput
                v-model="confirmPassword"
                label="Confirm New Password"
                name="confirm-password"
                type="password"
                :error="errors[0]"
              />
            </ValidationProvider>
            <div class="change-password-field">
              <el-button
                :disabled="invalid"
                type="primary"
                native-type="submit"
              >
                Change Password
              </el-button>
              <el-button
                type="outline"
                @click="changePasswordFormVisible = false, resetChangePasswordForm()"
              >
                Cancel
              </el-button>
            </div>
          </form>
        </ValidationObserver>
        <a
          v-if="!changePasswordFormVisible"
          class="change-password-toggle"
          role="button"
          tabindex="0"
          @click="changePasswordFormVisible = true"
        >Change Password</a>
      </template>
    </ContentCard>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth'
import PageHeader from '@/components/PageHeader/PageHeader'
import ContentCard from '@/components/ContentCard/ContentCard'
import NotificationTray from '@/components/NotificationTray'
import DataForm from '@/components/DataForm/DataForm'
import DataField from '@/components/DataField/DataField'
import BfInput from '@/components/BfInput/BfInput'
import BfPassword from '@/components/BfPassword/BfPassword'
import GET_USER_QUERY from '@/graphql/users/GetUserQuery.graphql'
import GET_CURRENT_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import UPDATE_USER_MUTATION from '@/graphql/users/UpdateUserMutation.graphql'
import { logError } from '@/utils/logging'

export default {

  components: {
    PageHeader,
    ContentCard,
    NotificationTray,
    DataForm,
    DataField,
    BfInput,
    BfPassword
  },

  apollo: {
    user() {
      this.$store.dispatch('updateLoading', true)
      return {
        query: this.userQuery,
        variables: this.userVars,
        update: data => ({ userId: data.getUser.id, ...data.getUser }),
        result () {
          this.$store.dispatch('updateLoading', false)
        },
        error (error) {
          logError(error, 'UserProfile.vue user query')
        }
      }
    },
    currentUser() {
      return {
        query: GET_CURRENT_USER_QUERY,
        fetchPolicy: 'cache-first',
        update: data => ({ userId: data.getUser.id, ...data.getUser }),
        error (error) {
          logError(error, 'UserProfile.vue current user query')
        }
      }
    }
  },

  data() {
    return {
      currentUser: {},
      user: {},
      sections: [{
        rows: 3,
        gridFlow: 'column',
        fields: [
          {
            id: 'firstName',
            label: 'First Name',
            type: 'text',
            validationRules: 'required'
          },
          {
            id: 'middleName',
            label: 'Middle Name',
            type: 'text'
          },
          {
            id: 'lastName',
            label: 'Last Name',
            type: 'text',
            validationRules: 'required'
          },
          {
            id: 'emailAddress',
            label: 'Email Address',
            type: 'email',
            validationRules: 'required|email'
          },
          {
            id: 'jobTitle',
            label: 'Job Title',
            type: 'text'
          },
          {
            id: 'phoneNumber',
            label: 'Phone Number',
            type: 'text'
          }
        ]
      }],
      notifications: [],
      editing: false,
      changePasswordFormVisible: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },

  computed: {
    isCurrentUser() {
      return this.user.userId && this.user.userId === this.currentUser.userId
    },

    pageTitle() {
      return this.isCurrentUser
        ? 'My Profile'
        : 'User Profile'
    },

    /*
     * The following two computed props allow for the possibility of viewing
     * another user's profile, which could be useful to administrators.
     * Can be removed if determined to not be in scope.
     */
    userQuery() {
      return this.$route.params.userId
        ? GET_USER_QUERY
        : GET_CURRENT_USER_QUERY
    },

    userVars() {
      return this.$route.params.userId
        ? { userId: this.$route.params.userId }
        : null
    },

    isUserValid() {
      return this.$refs.userValidator.flags.valid
    }
  },

  methods: {
    handleChangePasswordSubmit() {
      this.clearNotifications()
      this.changeUserPassword()
    },
    changeUserPassword() {
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, this.oldPassword, this.confirmPassword))
        .then(() => {
          this.notifications.push({
            id: 0,
            type: 'success',
            title: 'Success!',
            message: 'Password updated successfully.'
          })
          this.changePasswordFormVisible = false
          this.resetChangePasswordForm()
        })
        .catch(error => {
          logError(error, 'UserProfile.vue change password')
          this.notifications = [{
            id: 0,
            type: 'error',
            title: 'Error',
            message: 'Failed to change password. Please try again.'
          }]
        })
    },
    resetChangePasswordForm() {
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
    },
    toggleEditMode () {
      this.clearNotifications()
      if (this.editing) {
        this.updateUser()
      }
      this.editing = !this.editing
    },

    clearNotifications() {
      this.notifications = []
    },

    updateUser() {
      this.$apollo.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: this.user
      })
        .then(response => {
          if (response.data.updateUser.emailAddress !== this.user.emailAddress) {
            this.notifications.push({
              id: 0,
              type: 'success',
              title: 'Success!',
              message: 'You must verify your new email address. Follow the instructions ' +
                `we've sent to ${this.user.emailAddress} to verify your new address.`
            })
            this.user.emailAddress = response.data.updateUser.emailAddress
          } else {
            this.notifications.push({
              id: 0,
              type: 'success',
              title: 'Success!',
              message: 'Profile updated successfully.'
            })
          }
        })
        .catch(error => {
          logError(error, 'UserProfile.vue update user mutation')
          this.notifications.push({
            id: 0,
            type: 'error',
            title: '',
            message: 'There was an error saving the updated user.'
          })
        })
    }

  }
}
</script>

<style lang="scss">
  .user-settings {
    .change-password-toggle {
      display: inline-block;
      padding: 1.5rem 1rem 1rem 0;
      @include text-style('interface', 'small', 'medium')
    }

    .data-form {
      margin-top: 0;
    }

    .change-password-grid {
      display: grid;
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;

      .change-password-field {
        grid-column: 1 / 2;
      }

      .el-button {
        margin-right: 1rem;
      }
    }
  }
</style>
