<template>
  <FormFieldWrap
    class="bf-date-picker"
    v-bind="$props"
    icon="calendar"
    icon-width="20"
  >
    <!-- TODO look into using browser native date picker -->
    <el-date-picker
      ref="datepicker"
      v-model="localState"
      v-bind="$attrs"
      format="MM/dd/yyyy"
      :value-format="$attrs['value-format'] ? $attrs['value-format'] : 'MM/dd/yyyy'"
      :placeholder="$attrs['placeholder'] ? $attrs['placeholder'] : 'mm/dd/yyyy'"
      :picker-options="datePickerOptions"
      :default-value="defaultValue"
      v-on="$listeners"
    />
  </FormFieldWrap>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
import { displayDateForDatepicker, getUTCDate, getDefaultMinDate, getDefaultMaxDate } from '@/utils/date'

export default {
  name: 'BfDatePicker',
  components: {
    FormFieldWrap
  },
  props: FormFieldWrap.props,
  data () {
    return {
      datePickerOptions: {
        disabledDate: this.disabledDates
      }
    }
  },
  computed: {
    // Using localState allows us to modify the format of the input value for display
    localState: {
      get() {
        return this.$attrs.value ? displayDateForDatepicker(this.$attrs.value) : null
      },
      set(localState) { this.$emit('input', localState) }
    },
    disabledBeforeDate() {
      return this.$attrs['disable-before']
        ? getUTCDate(new Date(this.$attrs['disable-before']))
        : getUTCDate(getDefaultMinDate())
    },
    disabledAfterDate() {
      // Don't allow dates in the future to be selected
      return this.$attrs['disable-after']
        ? getUTCDate(new Date(this.$attrs['disable-after']))
        : getUTCDate(getDefaultMaxDate())
    },
    defaultValue() {
      const today = new Date()
      // If today is a valid date, then make that the default value
      if (!this.disabledDates(today)) {
        return today
      }

      if (this.disabledAfterDate < today) {
        return this.disabledAfterDate
      }

      return this.disabledBeforeDate
    }
  },
  methods: {
    disabledDates(date) {
      if (this.disabledBeforeDate) {
        return date < this.disabledBeforeDate || date > this.disabledAfterDate
      } else {
        return date > this.disabledAfterDate
      }
    },

    /**
     * Helper method to use when programmatically opening the date picker.
     */
    openDatepicker() {
      this.$refs.datepicker.focus()
    }
  }
}
</script>
<style lang="scss">
.bf-date-picker {
  // hide calendar icon when disabled
  &[disabled] .icon-wrapper {
    display: none;
  }
  .el-date-editor {
    width: 100%;

    .el-input__inner {
      padding-left: 1rem;
    }

    .el-input__prefix {
      display: none;
    }
    // position the clear icon to next to the calendar icon
    .el-input__suffix {
      right: 1.5rem
    }
  }
}
</style>
