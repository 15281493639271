var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-settings"},[_c('PageHeader',{attrs:{"title":_vm.pageTitle},scopedSlots:_vm._u([(!this.$route.params.userId)?{key:"actions",fn:function(){return [_c('el-button',{staticClass:"user-settings__edit-profile",attrs:{"type":"primary","disabled":_vm.editing && !_vm.isUserValid},on:{"click":_vm.toggleEditMode}},[(!_vm.editing)?_c('span',[_vm._v("Edit Profile")]):_c('span',[_vm._v("Save Profile")])])]},proxy:true}:null],null,true)}),_c('ContentCard',[_c('NotificationTray',{attrs:{"notifications":_vm.notifications}}),_c('ValidationObserver',{ref:"userValidator"},[_c('DataForm',{attrs:{"sections":_vm.sections,"value":_vm.user,"editing":_vm.editing}})],1),(_vm.isCurrentUser)?[_c('h3',[_vm._v("Change Password")]),(!_vm.changePasswordFormVisible)?_c('div',{staticClass:"change-password-grid"},[_c('DataField',{staticClass:"change-password-field",attrs:{"label":"Current Password"}},[_vm._v(" ********** ")])],1):_c('ValidationObserver',{staticClass:"change-password-grid",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleChangePasswordSubmit($event)}}},[_c('ValidationProvider',{staticClass:"change-password-field",attrs:{"name":"oldPassword","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BfInput',{attrs:{"label":"Old Password","name":"oldPassword","type":"password","autofocus":"","error":errors[0]},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"change-password-field",attrs:{"name":"newPassword","rules":"required|password","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BfPassword',{attrs:{"label":"New Password*","name":"newPassword","autofocus":"","error":errors[0]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"change-password-field",attrs:{"rules":"required|sameAsPassword:@newPassword","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BfInput',{attrs:{"label":"Confirm New Password","name":"confirm-password","type":"password","error":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('div',{staticClass:"change-password-field"},[_c('el-button',{attrs:{"disabled":invalid,"type":"primary","native-type":"submit"}},[_vm._v(" Change Password ")]),_c('el-button',{attrs:{"type":"outline"},on:{"click":function($event){_vm.changePasswordFormVisible = false, _vm.resetChangePasswordForm()}}},[_vm._v(" Cancel ")])],1)],1)]}}],null,false,1264773933)}),(!_vm.changePasswordFormVisible)?_c('a',{staticClass:"change-password-toggle",attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){_vm.changePasswordFormVisible = true}}},[_vm._v("Change Password")]):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }